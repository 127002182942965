
import { MediaType } from "@evercam/shared/types/media"
import MediaUrlField from "@/components/mediaHub/MediaUrlField"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent, MediaFileType } from "@evercam/shared/types"

export default {
  name: "MediaShareDialog",
  components: {
    MediaUrlField,
  },
  props: {
    mediaItem: {
      type: Object,
      default: () => {},
    },
    mediaUrl: {
      type: String,
      default: () => "",
    },
    isMediaPublic: {
      type: Boolean,
      default: () => false,
    },
    mediaType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      dialog: false,
      beforeImage: "",
      afterImage: "",
      clipDuration: "",
      socialLinks: [
        {
          event: "facebook",
          icon: "fab fa-facebook-f",
          url: (url) => `http://www.facebook.com/sharer.php?u=${url}`,
          color: "#3B5998",
          label: "Share",
        },
        {
          event: "linkedin",
          icon: "fab fa-linkedin",
          url: (url) =>
            `http://www.linkedin.com/shareArticle?url=${url}&title=My photo&summary=This is a photo from evercam`,
          color: "#0077B5",
          label: "Post",
        },
        {
          event: "whatsapp",
          icon: "fab fa-whatsapp",
          url: (url) => `https://web.whatsapp.com/send?text=${url}`,
          color: "#25D366",
          label: "Message",
        },
        {
          event: "twitter",
          icon: "fab fa-twitter",
          url: (url) => `http://twitter.com/share?url=${url}`,
          color: "#1DA1F2",
          label: "Tweet",
        },
      ],
      MediaFileType,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore, useCameraStore),
    shareUrl() {
      if (!this.mediaItem || !this.projectStore.selectedProject) {
        return ""
      }
      if (
        [MediaType.File, MediaType.EditedImage, MediaType.XRay]?.includes(
          this.mediaType
        )
      ) {
        return `${this.$config.public.apiURL}/projects/${this.projectStore.selectedProjectExid}/media-hub/${this.mediaItem.fileName}`
      }

      return `${window.location.origin}/v2/projects/${this.projectStore.selectedProjectExid}/media-hub/${this.mediaItem.exid}`
    },
    isTimelapseMediaType() {
      return this.mediaType === MediaType.Timelapse
    },
    isClipMediaType() {
      return this.mediaType === MediaType.Clip
    },
    isEditedImageMediaType() {
      return this.mediaType === MediaType.EditedImage
    },
    isCompareMediaType() {
      return this.mediaType === MediaType.Compare
    },
    showShare() {
      return (
        this.accountStore.token &&
        this.$permissions.project.has.socialMediaSharing()
      )
    },
    isExternalUrlMediaType() {
      return this.mediaType === MediaType.ExternalUrl
    },
    disableShare() {
      return !this.isMediaPublic && !this.cameraStore.isCameraPublic
    },
    shareTooltip() {
      if (this.disableShare) {
        return this.$t("content.media_hub.share_disable_tooltip")
      }

      return ""
    },
    mediaMp4Url() {
      return this.mediaUrl.replace(/mp4.*/gi, MediaFileType.MP4)
    },
    mediaGifUrl() {
      return this.mediaUrl.replace(/mp4.*/gi, MediaFileType.GIF)
    },
  },
  methods: {
    getShare() {
      if (this.isCompareMediaType) {
        const params = this.mediaItem?.embedCode?.split(" ") || []
        this.beforeImage = `${this.$config.public.s3BucketURL}/${
          this.mediaItem.projectExid
        }/compares/${params[6]}/start-${params[4]}.jpg?${Math.random()}`
        this.afterImage = `${this.$config.public.s3BucketURL}/${
          this.mediaItem.projectExid
        }/compares/${params[6]}/end-${params[5]}.jpg?${Math.random()}`
      }
      if (this.isClipMediaType) {
        const first = new Date(this.mediaItem.fromDate)
        const last = new Date(this.mediaItem.toDate)
        const diffTime = Math.abs(first.getTime() - last.getTime())
        const diffMinutes = Math.ceil(diffTime / (1000 * 60))
        this.clipDuration = diffMinutes
      }
    },
    trackEvent(shareTo) {
      this.$analytics.saveEvent(AnalyticsEvent.mediaShareToPlatform, {
        platform: shareTo,
        mediaType: this.mediaType,
      })
    },
  },
}
