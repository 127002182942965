
import { Media, MediaType } from "@evercam/shared/types/media"
import Vue, { PropType } from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { navigateTo } from "#app"

export default Vue.extend({
  name: "MediaOverviewHeader",
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    mediaItem: {
      type: Object as PropType<Media>,
      default: () => ({} as Media),
    },
  },
  computed: {
    ...mapStores(useMediaHubStore, useProjectStore, useCameraStore),
  },
  methods: {
    deleteMedia() {
      let controller
      if (this.mediaItem.type === MediaType.Compare) {
        controller = EvercamApi.compares
      } else if (this.mediaItem.type === MediaType.Timelapse) {
        controller = EvercamApi.timelapse
      } else {
        controller = EvercamApi.mediaHub
      }

      return controller?.cDelete(
        this.projectStore.selectedProjectExid,
        this.mediaItem.exid
      )
    },
    async openDeleteDialog() {
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.delete_media"),
          message: this.$t("content.media_hub.delete_media"),
        })
      ) {
        this.handleDelete()
      }
    },
    handleDelete() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaDelete, {
        type: this.mediaItem.type,
      })
      this.deleteMedia()
        .then(() => {
          this.mediaHubStore.forceReloadMediaHub()
          this.$notifications.success(
            this.$t("content.media_hub.delete_success")
          )
          this.goBack()
        })
        .catch((error) => {
          this.$notifications.error({
            text: this.$t("content.media_hub.delete_error"),
            error,
          })
        })
    },
    goBack() {
      this.cameraStore.selectedCamera = null
      this.$analytics.saveEvent(AnalyticsEvent.mediaGoToMediaHub, {
        type: this.mediaItem.type,
      })

      return navigateTo(
        `${this.projectStore.projectRoute}/media-hub${
          this.mediaHubStore.filterParamsStringify
            ? `?${this.mediaHubStore.filterParamsStringify}`
            : ""
        }`
      )
    },
  },
})
